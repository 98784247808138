import { Injectable } from '@tanbo/vue-di-plugin'

import { HttpService } from '@/services/http.service'

export interface applyFormTy {
  /** 业务名称 */
  soft_type: number
}

export interface applyTableTY {
  url: string
  accid: string
  tag: string
  desc: string
}

export interface applyTableRequestData {
  code: number
  data: applyTableTY[]
  msg: string
}

export interface addApplyFormTy {
  accid_type: number
  count: number
  soft_type: number
}

export interface changeApplyFormTy {
  accid: number
  tag: string
}

/* 表单规范 */
export const applyFormRulesData = {
  soft_type: [
    {
      required: true,
      message: '请选择业务名称',
      trigger: 'change',
    },
  ]
}
export const addApplyFormRulesTY = {
  accid_type: [
    {
      required: true,
      message: '请选择或填写子渠道个数',
      trigger: 'change',
    },
  ],
  count: [
    {
      required: true,
      message: '请设置子渠道类型',
      trigger: 'change',
    },
  ]
}
export const changeApplyFormRulesData = {
  accid: [
    {
      required: true,
      message: '请确认计费ID',
      trigger: 'blur',
    },
  ],
  tag: [
    {
      required: true,
      message: '请修改渠道名称',
      trigger: 'blur',
    },
  ]
}

export const countDataList = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

@Injectable()
export class ApplyService {
  constructor (private http: HttpService) {}

  doGetApplyTableInfo (params: applyFormTy) {
    return this.http.post<applyTableRequestData>('/get_channels_accid.json', params).then((response) => {
      return response
    })
  }

  doaddApplySecondary (params: addApplyFormTy) {
    return this.http.post<applyTableRequestData>('/gen_channels_accid.json', params).then((response) => {
      return response
    })
  }

  doChangeApplySecondary (params: changeApplyFormTy) {
    return this.http.post<applyTableRequestData>('/update_accid_tag.json', params).then((response) => {
      return response
    })
  }
}
