/**
 * 拷贝内容
 * @description 获得前一天的数据
 * @param content 拷贝的内容
 */
 export function copyToClipboard (content: string) {
  (function (contentText) { // oncopy 事件在用户拷贝元素上的内容时触发。
    document.oncopy = function (e) {
      e.clipboardData.setData('text', contentText);
      e.preventDefault(); // 取消事件的默认动作
      document.oncopy = null;
    }
  })(content)
  document.execCommand('Copy') // execCommand方法是执行一个对当前文档/当前选择/给出范围的命令。 'Copy':将当前选中区复制到剪贴板
}
