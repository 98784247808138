
import { defineComponent, reactive, ref, computed, toRefs, onUnmounted } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import Tips from '@/components/tips/tips.vue'

import {
  applyFormRulesData,
  addApplyFormRulesTY,
  ApplyService,
  countDataList,
  changeApplyFormRulesData
} from './apply.service'
import { SoftService, channelSoftInfosTY } from '@/services/soft.service'

import { copyToClipboard } from '@/utils/copy'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: '',
  components: {
    MainLayout,
    Tips,
  },
  setup() {
    const injector = useReflectiveInjector([ApplyService])
    const service = injector.get(ApplyService)
    const softService = injector.get(SoftService)
    /* 表单信息 */
    const state = reactive({
      applyForm: {
        soft_type: 0
      },
      applyFormRules: applyFormRulesData
    })
    /* 网页标题 */
    const softName = ref('')
    /* 网页标题 */
    const softImg = computed(() => {
      const softType: number = state.applyForm.soft_type
      if (softType === 0 || softType === 3 || softType === 7 || softType === 10 || softType === 11 || softType > 14) {
        return require('./_images/1.png')
      }
      return require(`./_images/${softType}.png`)
    })
    let setTimeFunc: any = null
    /* 业务名称 */
    const channelSoftInfos = ref<channelSoftInfosTY[]>([] as channelSoftInfosTY[])
    /* 订阅业务名称 */
    const subscription = softService.channelSoftInfos.subscribe(list => {
      if (list.length > 0) {
        channelSoftInfos.value = list
        state.applyForm.soft_type = list[0].softid
        softName.value = list[0].softname
        setTimeFunc = setTimeout(() => { // 表单校验
          onSubmit()
        },0)
      }
    })
    onUnmounted(() => {
      // 组件销毁时，取消订阅
      subscription.unsubscribe()
      if (setTimeFunc) {
        clearTimeout(setTimeFunc)
      }
    })
    function changeChannel(val: number) {
      onSubmit()
      channelSoftInfos.value.forEach(element => {
        if (element.softid === val) {
          softName.value = element.softname
        }
      })
    }

    const applyFormRef = ref()
    const applyTableData = ref<any>([])
    function onSubmit () {
      applyFormRef.value.validate((valid: any) => {
        if (!valid) return
        service.doGetApplyTableInfo(state.applyForm).then((response) => {
          applyTableData.value = response?.data.reverse() || []
          ElMessage.success('成功获取数据')
        }).catch((err) => {
          ElMessage.error(`查询数据失败,${err.msg}`)
        })
      })
    }
    const dialogAddFormVisible = ref<boolean>(false)
    /* 添加新渠道弹窗 */
    function addNewApply () {
      dialogAddFormVisible.value = true
    }

    const addApplyFormState = reactive({
      addApplyForm: {
        accid_type: 0,
        count: 0
      },
      countData: countDataList
    })
    const addApplyFormRef = ref()
    const addApplyFormRules = ref(addApplyFormRulesTY)

    /* 添加新渠道请求 */
    function addApplySecondary () {
      addApplyFormRef.value.validate((valid: any) => {
        if (!valid) return
        const parse = {
          accid_type: getAccidType(),
          count: addApplyFormState.addApplyForm.count,
          soft_type: state.applyForm.soft_type
        }
        service.doaddApplySecondary(parse).then((response) => {
          ElMessage.success(response.msg)
          dialogAddFormVisible.value = false
          onSubmit()
        }).catch((err) => {
          ElMessage.error(`查询数据失败,${err.msg}`)
        })
      })
    }
    function getAccidType() {
      if (state.applyForm.soft_type === 0) {
        return 4
      } else if (state.applyForm.soft_type === 4) {
        return 3
      }
      return addApplyFormState.addApplyForm.accid_type
    }
    /* 修改备注弹窗 */
    function modifyApply(index: number, row: any) {
      changeFormState.dialogchangeFormVisible = true
      changeFormState.changeApplyForm.accid = row.accid
      changeFormState.changeApplyForm.tag = row.tag
    }
    const changeFormState = reactive({
      dialogchangeFormVisible: false,
      changeApplyForm: {
        accid: 0,
        tag: ''
      },
      changeApplyFormRules: changeApplyFormRulesData
    })
    const changeApplyFormRef = ref()
    function changeApplySecondary () {
      changeApplyFormRef.value.validate((valid: any) => {
        if (!valid) return
        service.doChangeApplySecondary(changeFormState.changeApplyForm).then((response) => {
          ElMessage.success(response.msg)
          changeFormState.dialogchangeFormVisible = false
          onSubmit()
        }).catch((err) => {
          ElMessage.error(`修改数据失败,${err.msg}`)
        })
      })
    }
    function copyUrl(index: number, row: any) {
      copyToClipboard(row.url)
      ElMessage.success('复制成功！')
    }

    return {
      channelSoftInfos,
      softImg,
      softName,
      changeChannel,
      ...toRefs(state),
      applyFormRef,
      onSubmit,
      applyTableData,
      addNewApply,
      dialogAddFormVisible,
      ...toRefs(addApplyFormState),
      addApplyFormRef,
      addApplyFormRules,
      addApplySecondary,
      modifyApply,
      ...toRefs(changeFormState),
      changeApplySecondary,
      changeApplyFormRef,
      copyUrl
    };
  },
});
